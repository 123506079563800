import React from 'react'
import { Helmet, MetaProps } from 'react-helmet'
import faviconUrl from '../images/favicon.ico'

interface Props {
  title: string
  lang?: string
  meta?: MetaProps[]
  description?: string
}
const fbImg = `https://wholelawyers.com/statics/share-facebook.png`
const ttImg = `https://wholelawyers.com/statics/share-twitter.png`
export default function SEO({
  title,
  description,
  meta = [
    {name: 'referrer', content: 'never'},
    {property: 'itemprop', content: fbImg},
    {property: 'og:image', content: fbImg},
    {name: 'twitter:image', content: ttImg},
    {name: 'msapplication-TileImage', content: fbImg},
  ],
  lang = 'zh-CN',
}: Props) {
  const metaDescription =
    description ||
    '一分钟美国法律旨帮助在美华人解决日常的法律问题。搜索相关法律问题，律师干货视频免费看。官方YouTube频道：One Minute US Law。移民法、婚姻法、信托、公司法及更多法律领域。'

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate="%s · 一分钟美国法律"
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, user-scalable=no',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'AILaw',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
      link={[
        {
          rel: 'icon',
          href: faviconUrl,
        },
      ]}
    />
  )
}
